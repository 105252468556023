import { BONUS_TYPES, INQUIRY_TYPES, PRODUCT_OPTION_CRITERIA_TYPES, PRODUCT_OPTION_SORTS } from "./constants";

export const initDestination = (destination = {}) => ({
    ...destination,
    title: destination?.title || null,

    name: destination?.name || null,
    phone: destination?.phone || null,
    email: destination?.email || null,
    postcode: destination?.postcode || null,
    address1: destination?.address1 || null,
    address2: destination?.address2 || null,

    isDefault: destination?.isDefault || false,
});

export const initUser = (user = {}) => ({
    ...user,

    _terms: user?._terms || [],

    company: {
        businessLicense: user?.company?.businessLicense || null,
        businessNo: user?.company?.businessNo || null,
        name: user?.company?.name || null,
        ceo: user?.company?.ceo || null,
    },

    username: user?.username || null,
    password: user?.password || null,

    name: user?.name || null,
    email: user?.email || null,
    phone: user?.phone || null,
});

export const initBank = (bank = {}) => ({
    ...bank,
    code: bank?.code || null,
    accountNumber: bank?.accountNumber || null,
    accountHolder: bank?.accountHolder || null,
});

export const initPromoterApprovalInquiry = () => ({
    _terms: [],
    type: INQUIRY_TYPES.PROMOTER_APPROVAL.value,
    ci: null,
    phone: null,
    bank: initBank(),
    residentRegistrationNumber: null,
});

export const initOrderReceiver = ({ receiver: { name = null, phone = null, email = null, postcode = null, address1 = null, address2 = null } = {} } = {}) => ({
    name,
    phone,
    email,
    postcode,
    address1,
    address2,
});

export const initOrder = (order = {}) => ({
    ...order,
    receiver: initOrderReceiver(order),
    requestMessage: order?.requestMessage || null,
});

export const initPurchase = (purchase = {}) => ({
    ...purchase,
    claimStatus: purchase?.claimStatus || null,
    claimReason: purchase?.claimReason || null,
    claimReasonDetails: purchase?.claimReasonDetails || null,
});

export const initBonus__debit = (bonus = {}) => ({
    _certification: bonus?._certification || null,

    type: bonus?.type || BONUS_TYPES.BONUS__DEBIT.value,
    amount: bonus?.amount || 0,
    remain: bonus?.remain || 0,
    meta: {
        ...(bonus?.meta || {}),
        bank: initBank(bonus?.meta?.bank),
        charge: bonus?.meta?.charge || 0,
    },
});

export const initShopReview = (review = {}) => ({
    ...review,

    _product: review?._product || null,
    _purchase: review?._purchase || null,

    subject: review?.subject || null,
    content: review?.content || null,
    reply: review?.reply || null,
    star: review?.star || null,
    images: review?.images || [],
    product: review?.product || null,
});

export const initBankAction = (action = {}) => ({
    ...action,

    bank: initBank(action?.bank),
});

export const initBankHoldVerification = (verification = {}) => ({
    ...initBankAction(verification),

    sendAmount: verification?.sendAmount || null,
    senderName: verification?.senderName || null,
    attemptCount: verification?.attemptCount || 0,

    code: null,

    isSuccess: verification?.isSuccess || false,
    isOutDated: verification?.isOutDated || false,
});

export const initBandBanner = (bandBanner = {}) => ({
    ...bandBanner,

    code: bandBanner?.code || null,
    name: bandBanner?.name || null,
    url: bandBanner?.url || null,

    display: {
        ...(bandBanner?.display || {}),
        enabled: bandBanner?.display?.enabled || false,
        hasPeriod: bandBanner?.display?.hasPeriod || false,
        startedAt: bandBanner?.display?.startedAt || null,
        endedAt: bandBanner?.display?.endedAt || null,
    },
});

export const initProductOptionCriterionValue = (value = {}) => ({
    ...value,
    img: value.img || null,
    name: value.name || null,
    hexa: value.hexa || null,

    tempId: value.tempId || Math.random(),
});

export const initProductOptionCriterion = (criterion = {}) => ({
    ...criterion,
    type: criterion.type || PRODUCT_OPTION_CRITERIA_TYPES.OTHER.value,
    name: criterion.name || null,
    values: (criterion.values || []).map(initProductOptionCriterionValue),

    tempId: criterion.tempId || Math.random(),
});

export const initProduct = (product = {}) => ({
    ...product,

    options: product.options || [],
    optionsConf: {
        ...(product.optionsConf || {}),
        enabled: product.optionsConf?.enabled || false,
        sort: product.optionsConf?.sort || PRODUCT_OPTION_SORTS.LATEST.value,
        criteria: (product.optionsConf?.criteria || []).map(initProductOptionCriterion),
    },
});

export const initProductOption = (option = {}) => ({
    ...option,
    hexa: option.hexa || null,
    name: option.name || null,
    value: option.value || null,
    price: option.price || 0,
    stock: option.stock || 0,
    enabled: option.enabled,

    tempId: option.tempId || Math.random(),
    tempId__color: option.tempId__color || null,
});

export const initSetting = (setting = {}) => ({
    ...setting,
    openai: { ...(setting.openai || {}) },
    promptGroups: [...(setting.promptGroups || [])],
});

export const initPrompt = (prompt = {}) => ({
    ...prompt,

    group: prompt.group || null,

    index: prompt.index || null,
    query: prompt.query || null,
    reply: prompt.reply || null,

    isEnabled: prompt.isEnabled || false,
});

export const initTemplate = (template = {}) => ({
    ...template,

    name: template?.name || null,
    subject: template?.subject || null,
    content: template?.content || null,
    center: template?.center || null
})